<template>
  <div>
    <div class="row" v-for="link in links" :key="link.name">
      <div class="col-12">
        <h2 class="my-4 font-weight-bolder">{{ link.name }}</h2>
        <hr />
      </div>
      <div
        class="col-6 col-sm-4 col-md-3 col-lg-2"
        v-for="menu in link.menu"
        :key="menu.name"
      >
        <a :href="menu.link" target="_blank">
          <div :class="'card my-3 shadow ' + link.color">
            <div class="card-header bg-white">
              <img
                :src="menu.img"
                class="w-100 img-fluid"
                :alt="menu.name + ' logo'"
              />
            </div>
            <div class="p-2">
              <h6 class="card-title text-center m-0 font-weight-bolder">
                {{ menu.name }}
              </h6>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    links: [
      {
        name: "General Tools",
        color: "hover-blue",
        menu: [
          {
            name: "Lula Wiki",
            desc: "",
            link: "https://lulawiki.lulaloop.co.za",
            img: require("@/assets/book.png"),
          },
          {
            name: "Meetings",
            desc: "",
            link: "https://meet.google.com",
            img: require("@/assets/meets.png"),
          },
          {
            name: "Calendar",
            desc: "",
            link: "https://calendar.google.com",
            img: require("@/assets/calendar.png"),
          },
          {
            name: "Mail",
            desc: "",
            link: "https://mail.google.com",
            img: require("@/assets/gmail.png"),
          },
          {
            name: "QA tests",
            desc: "",
            link: "/qa",
            img: require("@/assets/QA.svg"),
          },
          {
            name: "Slack",
            desc: "",
            link: "http://lulaloop.slack.com",
            img: require("@/assets/slack.png"),
          },
          {
            name: "Drive",
            desc: "",
            link: "https://drive.google.com",
            img: require("@/assets/drive.png"),
          },
          {
            name: "BambooHR",
            desc: "",
            link: "https://lulaloop.bamboohr.com",
            img: require("@/assets/bamboohr.png"),
          },
          {
            name: "Bug Report",
            desc: "",
            link: "https://forms.clickup.com/37407156/f/13njdm-1628/GEHSMBVE5I5S2XJTS9",
            img: require("@/assets/bugs.png"),
          },
          {
            name: "Feature Request",
            desc: "",
            link: "https://forms.clickup.com/37407156/f/13njdm-5267/LDZ9KV4H09KZXFIQ3U",
            img: require("@/assets/features.png"),
          },
          {
            name: "Stats",
            desc: "",
            link: "/stats",
            img: require("@/assets/stats.png"),
          },
          {
            name: "Changelogs",
            desc: "",
            link: "/changelogs",
            img: require("@/assets/Changelog.svg"),
          },
          {
            name: "Clickup",
            desc: "",
            link: "https://clickup.com/",
            img: require("@/assets/clickup.png"),
          },
        ],
      },
      {
        name: "Lula Services",
        color: "hover-red",
        menu: [
          {
            name: "Website",
            desc: "",
            link: "http://lulaloop.co.za/",
            img: require("@/assets/website.png"),
          },
          {
            name: "Dashboard",
            desc: "",
            link: "http://dashboard.lulaloop.co.za/",
            img: require("@/assets/dashboard.png"),
          },
          {
            name: "Organizations",
            desc: "",
            link: "http://organizations.lulaloop.co.za/",
            img: require("@/assets/organization.png"),
          },
          {
            name: "Operator",
            desc: "",
            link: "http://operator.lulaloop.co.za/",
            img: require("@/assets/operator.png"),
          },
          {
            name: "Demand",
            desc: "",
            link: "http://demand.lulaloop.co.za/",
            img: require("@/assets/operator.png"),
          },
          {
            name: "Commuter",
            desc: "",
            link: "http://commuter.lulaloop.co.za/",
            img: require("@/assets/commuter.png"),
          },
          {
            name: "Driver",
            desc: "",
            link: "http://driver.lulaloop.co.za/",
            img: require("@/assets/commuter.png"),
          },
          {
            name: "Covid19",
            desc: "",
            link: "https://covid19.lulaloop.co.za/",
            img: require("@/assets/covid.png"),
          },
          {
            name: "Delivery",
            desc: "",
            link: "https://delivery.lulaloop.co.za/",
            img: require("@/assets/delivery.png"),
          },
          {
            name: "Android",
            desc: "",
            link: "https://play.google.com/store/apps/developer?id=Lula+Team",
            img: require("@/assets/playstore.png"),
          },
          {
            name: "iOS",
            desc: "",
            link:
              "https://apps.apple.com/za/developer/lula-loop-pty-ltd/id1224507286",
            img: require("@/assets/applestore.png"),
          },
          {
            name: "Huawei",
            desc: "",
            link:
              "https://appgallery.huawei.com/#/app/C105858917",
            img: require("@/assets/HuaweiAppGallery.png"),
          },
          {
            name: "API",
            desc: "",
            link: "https://api.lulaloop.co.za",
            img: require("@/assets/postman.png"),
          },
        ],
      },
      {
        name: "Staging Environment",
        color: "hover-yellow",
        menu: [
          {
            name: "Website",
            desc: "",
            link: "https://staging-website.lulaloop.co.za/",
            img: require("@/assets/website.png"),
          },
          {
            name: "Dashboard",
            desc: "",
            link: "https://staging-dashboard.lulaloop.co.za/",
            img: require("@/assets/dashboard.png"),
          },
          {
            name: "Organizations",
            desc: "",
            link: "https://staging-organizations.lulaloop.co.za/",
            img: require("@/assets/organization.png"),
          },
          {
            name: "Operator",
            desc: "",
            link: "https://staging-operators.lulaloop.co.za/",
            img: require("@/assets/operator.png"),
          },
          {
            name: "Demand",
            desc: "",
            link: "https://staging-demand.lulaloop.co.za/",
            img: require("@/assets/operator.png"),
          },
          {
            name: "Commuter",
            desc: "",
            link: "https://staging-commuter.lulaloop.co.za/",
            img: require("@/assets/commuter.png"),
          },
          {
            name: "Driver",
            desc: "",
            link: "https://staging-driver.lulaloop.co.za/",
            img: require("@/assets/commuter.png"),
          },
          {
            name: "Delivery",
            desc: "",
            link: "https://staging-delivery.lulaloop.co.za/",
            img: require("@/assets/delivery.png"),
          },
          {
            name: "API",
            desc: "",
            link: "https://staging-api.lulaloop.co.za",
            img: require("@/assets/postman.png"),
          },
        ],
      },
      {
        name: "Development Environment",
        color: "hover-green",
        menu: [
          {
            name: "Website",
            desc: "",
            link: "https://dev-website.lulaloop.co.za",
            img: require("@/assets/website.png"),
          },
          {
            name: "Dashboard",
            desc: "",
            link: "https://dev-dashboard.lulaloop.co.za",
            img: require("@/assets/dashboard.png"),
          },
          {
            name: "Organizations",
            desc: "",
            link: "https://dev-organizations.lulaloop.co.za",
            img: require("@/assets/organization.png"),
          },
          {
            name: "Operator",
            desc: "",
            link: "https://dev-operators.lulaloop.co.za",
            img: require("@/assets/operator.png"),
          },
          {
            name: "Commuter",
            desc: "",
            link: "https://dev-commuter.lulaloop.co.za",
            img: require("@/assets/commuter.png"),
          },
          {
            name: "Driver",
            desc: "",
            link: "https://dev-driver.lulaloop.co.za",
            img: require("@/assets/commuter.png"),
          },
          {
            name: "Covid19",
            desc: "",
            link: "https://dev-covid19.lulaloop.co.za/",
            img: require("@/assets/covid.png"),
          },
          {
            name: "Delivery",
            desc: "",
            link: "https://dev-delivery.lulaloop.co.za/",
            img: require("@/assets/delivery.png"),
          },
          {
            name: "API",
            desc: "",
            link: "https://dev-api.lulaloop.co.za",
            img: require("@/assets/postman.png"),
          },
        ],
      },
      {
        name: "Development Tools",
        color: "hover-blue",
        menu: [
          {
            name: "Mailgun",
            desc: "",
            link: "https://www.mailgun.com/",
            img: require("@/assets/mailgun.svg")
          },
          {
            name: "Bitbucket",
            desc: "",
            link: "https://bitbucket.org/",
            img: require("@/assets/bitbucket.svg")
          },
          {
            name: "Forge",
            desc: "",
            link: "https://forge.laravel.com/",
            img: require("@/assets/forge.png"),
          },
          {
            name: "New Relic",
            desc: "",
            link: "https://newrelic.com/",
            img: require("@/assets/newrelic.svg")
          },
          {
            name: "D. Ocean",
            desc: "",
            link: "https://cloud.digitalocean.com/login",
            img: require("@/assets/digitalocean.svg")
          },
          {
            name: "Laravel",
            desc: "",
            link: "https://laravel.com/",
            img: require("@/assets/laravel.png"),
          },
          {
            name: "Vue.js",
            desc: "",
            link: "https://vuejs.org/",
            img: require("@/assets/vuejs.svg")
          },
          {
            name: "Bootstrap",
            desc: "",
            link: "https://bootstrap-vue.js.org/",
            img: require("@/assets/bootstrap.png"),
          },
          {
            name: "Google",
            desc: "",
            link: "https://accounts.google.com/",
            img: require("@/assets/google.svg")
          },
          {
            name: "C Panel",
            desc: "",
            link: "https://cpanel.lulaloop.co.za/",
            img: require("@/assets/cpanel.png"),
          },
          {
            name: "Gridhost",
            desc: "",
            link: "https://1-grid.com/client/clientarea.php",
            img: require("@/assets/gridhost.png"),
          },
          {
            name: "Ionic",
            desc: "",
            link: "https://ionicframework.com/docs",
            img: require("@/assets/ionic.png"),
          },
          {
            name: "Clickatell",
            desc: "",
            link: "https://www.clickatell.com/sign-in/",
            img: require("@/assets/clickatell.png"),
          },
          {
            name: "Xcode",
            desc: "",
            link: "https://developer.apple.com/",
            img: require("@/assets/xcode.png"),
          },
          {
            name: "Android Studio",
            desc: "",
            link: "https://developer.android.com/studio",
            img: require("@/assets/androidStudio.png"),
          },
          {
            name: "Flutter",
            desc: "",
            link: "https://flutter.dev/docs",
            img: require("@/assets/flutter.png"),
          },
          {
            name: "Socket IO",
            desc: "",
            link: "https://socket.io/docs/",
            img: require("@/assets/socket.png")
          },
          {
            name: "Angular",
            desc: "",
            link: "https://angular.io/docs",
            img: require("@/assets/angular.png"),
          },
          {
            name: "OneSignal",
            desc: "",
            link: "https://documentation.onesignal.com/docs",
            img: require("@/assets/onesignal.png"),
          },
          {
            name: "Capacitor",
            desc: "",
            link: "https://capacitor.ionicframework.com/docs/",
            img: require("@/assets/capacitor.svg")
          },
          {
            name: "Postman",
            desc: "",
            link: "https://www.postman.com/",
            img: require("@/assets/postman.png"),
          },
        ],
      },
    ],
  }),

  methods: {},
};
</script>

<style>
.card,
.card-header {
  border-radius: 20px !important;
  border: none !important;
}
.card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.hover-red:hover {
  box-shadow: 0 0.5rem 1rem #e2420d !important;
}
.hover-blue:hover {
  box-shadow: 0 0.5rem 1rem #259de8 !important;
}
.hover-green:hover {
  box-shadow: 0 0.5rem 1rem #3cc380 !important;
}
.hover-yellow:hover {
  box-shadow: 0 0.5rem 1rem #f0cd66 !important;
}
a:hover {
  text-decoration: none !important;
}
</style>
